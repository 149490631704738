:root {
  --background-color: #00061c;
  --primary-color: #ffb100;
}

.fade-in {

  animation: fadeIn 1s;
  animation-delay: 0.1s;
  visibility: hidden;
  animation-fill-mode: forwards;
  /* delay the animation by 1 second */
}

@keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}


@font-face {
  font-family: "Agrandir";
  src: url("/public/fonts/Agrandir-Narrow.otf");
}

@font-face {
  font-family: "Formula";
  src: url("/public/fonts/FormulaCondensed-Regular.otf");
}

@font-face {
  font-family: "FormulaWide";
  src: url("/public/fonts/FormulaCondensedWide-Regular.otf");
}

.background {
  background-color: var(--background-color);
}


.footer {
  clear: both;
  padding: 0;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  margin: 0;
  position: fixed;
  bottom: 0px;
  width: 100%;
}


.header {
  overflow: hidden;
  padding: 20px 10px;
}

h1 {
  font-family: "Agrandir";
}

h2 {
  color: rgb(131, 130, 130);
  font-size: calc(8px + 0.7vw);
  font-weight: 400;
  font-family: "Agrandir";
  padding-bottom: 1px;

}

.wide {
  font-size: 5.4vw;
  color: #ffb100;
  text-transform: uppercase;
  font-family: "FormulaWide", sans-serif;
  width: 100%;
  text-align: left;
  font-weight: 400;
  padding-top: 10px;
}



h3 {
  font-size: 5.4vw;
  color: #ffb100;
  text-transform: uppercase;
  font-family: "Formula";
  text-align: left;
  letter-spacing: 0.5px;
  font-weight: 400;
}



.intro {
  font-size: 3.7vw;
  color: #ffb100;
  line-height: 4.0vw;
  text-transform: uppercase;
  font-family: "Formula";
  width: 100%;
  height: 100%;
  text-align: left;
  letter-spacing: 0.5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding-top: 10px;
}

.footer-links {
  margin-right: 10%;
  margin-bottom: 30px;
  font-family: 'Formula', sans-serif;
  color: var(--primary-color);
  font-size: 1.5vw;
  text-decoration: none;
  float: right;
  text-align: right;
}

.claim {
  margin-left: 4vw;
  font-family: 'Formula', sans-serif;
  color: var(--primary-color);
  font-size: 1.5vw;
  text-decoration: none;
  float: left;
  text-align: left;
}



img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.logo {
  width: 8vw;
  min-Width: 80px;
  max-width: 100%;
  width: 100%;
  height: auto;
  transition: opacity 200ms ease;
}

.inner {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}


.button-40 {
  background-color: var(--primary-color)!;
  border: 1px solid transparent;
  border-radius: .35rem;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .3rem 0.5rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  width: auto;
}

.button-40:hover {
  background-color: #374151;
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}


.center-container
{

  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-container {
  display: flex;
  height: 330px;
  width: 400px;
  background-color: var(--primary-color);
}

.form-field {
  display: flex;
  box-sizing: border-box;
  border-style: none;
  background-color: white;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  height: 40px;
  width: 100%;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.Container-All-TileSelection {
  display: grid;
  grid-gap: 15px;

}

.Container-TileSelection {
  display: flex;
  grid-gap: 10px;
}


.Square-Tile {
  position: relative;
  width: 35%;
  max-width: 250px;
  overflow: hidden;
}

.Square-Tile:before {
  content: "";
  display: block;
  padding-top: 100%;
}


.Tile-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: var(--primary-color) !important;
  background: var(--background-color);
  border: none;
  transition: background-color 0.4s ease-out;
}

.Tile-btn:hover {
  
  background: var(--primary-color);
}

.Tile-btn-nohover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: black !important;
  background: var(--primary-color);
  border: none;
  transition: background-color 0.4s ease-out;
}

.Tile-btn-disabled {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  cursor: pointer;
  color: var(--primary-color) !important;
  background: var(--background-color);
  border: none;
  transition: background-color 0.4s ease-out;
}

.Tile-btn-disabled-isClicked {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: var(--primary-color) !important;
  background: var(--primary-color);
  border: none;
}

.Tile-btn-column2 {
  
  text-align: start;
  color: white !important;
  font-size: calc(0.7vw + 4px);
  font-weight: 400;
  font-family: "Agrandir";
  padding: 15px;
  padding-top: 10px;
}

.tile-button-h1 {
  text-align: start;
  color: #ffb100;
  font-size: calc(1.0vw + 20px);
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Formula";
  padding: 15px;
  padding-top: 15px;
}

.tile-button-h1 :hover {
  color: #00061c;
}


.tile-button-h1-black {
  color: black;
  font-size: calc(3.5vw + 10px);
  line-height: 4.0vw;
  text-transform: uppercase;
  font-family: "Formula";
  width: 100%;
  height: 100%;
  text-align: left;
  letter-spacing: 0.5px;
  padding: 1vw;
  padding-top: 1vw;
  padding-right: 2vw;
}

.Tile-btn-column2-B {
  
  text-align: start;
  color: white !important;
  font-size: calc(0.7vw + 4px);
  font-weight: 400;
  font-family: "Agrandir";
  padding: 15px;
  padding-top: 10px;
}

.tile-button-h1-B {
  text-align: start;
  color: #ffb100;
  font-size: calc(1.2vw + 32px);
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Formula";
  padding: 15px;
  padding-top: 15px;
}

.tile-button-h1-B :hover {
  color: #00061c;
}


.tile-button-h1-black-B {
  color: black;
  font-size: calc(3.5vw + 10px);
  line-height: 4.0vw;
  text-transform: uppercase;
  font-family: "Formula";
  width: 100%;
  height: 100%;
  text-align: left;
  letter-spacing: 0.5px;
  padding: 1vw;
  padding-top: 1vw;
  padding-right: 2vw;

}


.Select-Buttons-Grid {
  grid-gap: 10px;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.Select-Btn-Small {
  display: flex;

  height: 100%;
  width: 100%;
}

.Tile-btn-small {
  font-family: "Agrandir";
  padding-left: 20px;
  padding-top: 5px;
  font-size: 1.2vw;
  font-weight: 400;
  cursor: pointer !important;
  text-align: left;
  background: var(--background-color);
  border: none;
  transition: var(--primary-color) 0.4s ease-out;
}

.selectButton {
  display: flex;
  background-color: black;
  height: 100%;
}

.Tile-btn-small-nohover {
  font-family: "Agrandir";
  padding-left: 20px;
  padding-top: 5px;
  font-size: 1.2vw;
  font-weight: 400;
  cursor: pointer !important;
  text-align: left;
  background: var(--primary-color);
  border: none;
}

.Tile-btn-small:hover {
  background: var(--primary-color);
}

.Tile-btn-small-h3 {
  text-align: center;
  color: var(--primary-color);
  font-size: 1vw;
  font-weight: 400;
  font-family: "Agrandir";
}



.textColorTransition {
  transition: color 0.4s ease-out;
}

.textfield {
  padding-left: 1%;
  background-color: #cacaca;
  font-size: 18px;
  font-family: "Formular", sans-serif;
  border-style: solid;
  border-color: rgb(95, 95, 95);
  height: 40px;
}

.label {
  font-size: 64;
}

input {
  outline: none;
}

.confirm-btn {
  cursor: pointer;
  margin-left: auto;
  margin-top: 15px;
  font-size: 24px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  color: black;
  background-color: var(--primary-color);
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.confirm-btn:disabled {
  cursor: auto;
}

.confirm-btn:hover:enabled {
  color: white;
  background-color: black;
}

.tile-grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  row-gap: 2em;
  column-gap: 2em;
}

.row {
  display: flex;

  /* use flexbox layout for the row */
}

.col {
  flex: 1;
  /* allow the columns to grow and shrink based on available space */
}

.col-60 {
  background-color: #F3F3F4;
  flex: 0 0 60%;
  /* set the column to take up 60% of the available space */
}

.col-40 {
  background-color: var(--background-color);
  flex: 0 0 40%;
  /* set the column to take up 40% of the available space */
}

/* optional responsive styles to adjust the column sizes at different screen sizes */


.grid {
  grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(3,
      1fr);
  grid-template-columns: auto auto auto;
}



.local-switcher {
  text-align: end;
  color: white;
  font-size: 26px;
  text-transform: uppercase;
  font-family: "Formula";
  letter-spacing: 0.5px;
  font-weight: 200;
}

.local-switcher2 {
  display: none;
  text-align: right;
  color: black;
  font-size: 46px;
  text-transform: uppercase;
  font-family: "Formula";
  letter-spacing: 0.5px;
  font-weight: 200;
}

.local-button {
  font-size: 26px;
  text-transform: uppercase;
  font-family: "Formula";
  letter-spacing: 0.5px;
  font-weight: 200;
  border: none;
  background-color: null;
  color: white;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}

.local-button:disabled {
  cursor: auto;

}

.local-button:hover:enabled {
  background-color: var(--primary-color)
}


@media (max-width: 1450px) {
  .Tile-btn-column2 {
    font-size: 10px;
  }
}

@media (max-width: 1800px) {


  .Tile-btn-disabled {
    font-size: calc(0.6vw + 36px);
  }

  .Tile-btn-nohover {
    font-size: calc(0.6vw + 36px);
  }

}

@media (max-width: 1200px) {
  .local-switcher2 {
    display: block;
    font-size: 30px;
  }

  h3 {
    font-size: 90px;
  }

  .wide {
    font-size: 90px;
  }

  .local-button {
    font-size: 46px;
  }

  .intro {
    padding-top: 40px;
    font-size: 42px;
    line-height: 46px
  }

  .local-switcher {
    display: none;
  }

  .local-button {
    font-size: 28px;
  }

  .footer-links {
    font-size: 28px;
  }



  .Tile-btn-small-nohover {
    font-size: 16px;
  }

  .Tile-btn-small-disabled {
    cursor: auto;
    font-size: 16px;
  }

  .Tile-btn-small {
    font-size: 16px;
  }

  .row {
    display: block;
    /* use flexbox layout for the row */
  }

  .col-60 {
    flex: 0 0 100%;
    /* set the column to take up 100% of the available space on small screens */
  }

  .col-40 {
    flex: 0 0 100%;
    /* set the column to take up 100% of the available space on small screens */
  }

  .Tile-btn-column2-B {
  
    text-align: start;
    color: white !important;
    font-size: calc(0.3vw + 10px);
    font-weight: 400;
    font-family: "Agrandir";
    padding: 15px;
    padding-top: 10px;
  }
  
  .tile-button-h1-B {
    text-align: start;
    color: #ffb100;
    font-size: calc(1.2vw + 42px);
    text-transform: uppercase;
    font-weight: 400;
    font-family: "Formula";
    padding: 15px;
    padding-top: 15px;
  }


}
html {
  height: 100%;
}
@media (max-width: 650px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    /* create a single column on very small screens */
  }


  .Container-TileSelection {
    display: grid;
    grid-gap: 10px;
    width: 100%;

  }

  .Square-Tile {
    position: none;
    width: 100%;
    height: 120px;
    max-width: none;
    overflow: none;
  }

  .Square-Tile:before {
    content: "";
    display: flex;
    padding-top: none;
  }

  

  .Tile-btn-disabled {
    font-size: calc(0.6vw + 48px);
  }

  .Tile-btn-nohover {
    font-size: calc(0.6vw + 48px);
  }

  .Tile-btn-column2 {
    margin-top:  -20px;
    font-size: calc(14px);
  }
  .Tile-btn-column2-B {
    margin-top:  -35px;
    text-align: start;
    color: white !important;
    font-size: calc(0.3vw + 10px);
    font-weight: 400;
    font-family: "Agrandir";
    padding: 15px;
    padding-top: 10px;
  }
  
  .tile-button-h1-B {
    text-align: start;
    color: #ffb100;
    font-size: calc(1.2vw + 26px);
    text-transform: uppercase;
    font-weight: 400;
    font-family: "Formula";
    padding: 15px;
    padding-top: 15px;
  }
  

}

@media (max-width: 450px) {

  .footer
  {
    display:flex;
  }
  .wide {
    font-size: 56px;
  }

  h3 {
    font-size: 56px;
  }


  .grid {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    /* create a single column on very small screens */
  }


  .Tile-btn-disabled {
    font-size: calc(0.6vw + 48px);
  }

  .Tile-btn-nohover {
    font-size: calc(0.6vw + 48px);
  }

  .Tile-btn-column2 {
    font-size: calc(14px);
  }
}